import { AnimatedSprite, Application, Assets, Spritesheet, Texture } from 'pixi.js';
import { CSSProperties, useCallback, useEffect, useRef } from 'react';

type Props = {
  width: number;
  height: number;
  style: CSSProperties;
  onComplete: () => void;
};

function AnimatedBomb({ width, height, style, onComplete }: Props) {
  const ref = useRef<HTMLCanvasElement>(null);
  const appRef = useRef<Application>();
  const spriteRef = useRef<AnimatedSprite>();

  const loadAsset = useCallback(async (name: string) => {
    let spritesheet: Spritesheet = Assets.cache.get(name);

    if (!spritesheet) {
      spritesheet = await Assets.load(name);
    }

    return spritesheet;
  }, []);

  const animate = useCallback(
    (textures: Texture[]) => {
      if (!appRef.current) return;

      const sprite = new AnimatedSprite(textures);
      sprite.anchor = 0.5;
      sprite.x = width / 2;
      sprite.y = height / 2;
      sprite.width = width;
      sprite.height = height;
      sprite.animationSpeed = 0.2;
      sprite.loop = false;
      sprite.onComplete = onComplete;
      sprite.play();

      spriteRef.current = sprite;
      appRef.current.stage.addChild(spriteRef.current);
    },
    [height, width, onComplete]
  );

  useEffect(() => {
    if (!ref.current || appRef.current) return;

    appRef.current = new Application();
    appRef.current.init({ width, height, backgroundAlpha: 0, canvas: ref.current });
  }, [height, width]);

  useEffect(() => {
    if (!appRef.current || spriteRef.current) return;

    loadAsset('sprite/bomb.json').then((spritesheet) => animate(spritesheet.animations.bomb));
  }, [animate, loadAsset]);

  return (
    <div style={style} className="absolute inset-0 flex items-center justify-center">
      <canvas ref={ref} />
    </div>
  );
}

export default AnimatedBomb;
